import React, { useEffect } from 'react';
import { SearchContainer } from './search-container';
import { useAppDispatch, useAppSelector } from '../../state';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { analytics } from '../../analytics';
import { useGetUserIsAdmin } from '../../components/user';
import { SearchTopBar } from '../../components/core/topBars';
import { search } from '../../state/actions/searchActions';
import { ISearchType } from '../../types/search.type';
import { createLink } from '../../utils/helperFunctions';

export interface Props {}
export const Search: React.FC<Props> = () => {
  useGetUserIsAdmin();
  const dispatch = useAppDispatch();
  const searchState = useAppSelector((s) => s.search);
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = qs.parse(location.search.substring(1));
  const query = queryString['q'] as string | '';
  const page = parseInt(queryString['p'] as string) || 1;
  const type = (queryString['t'] as ISearchType) || ISearchType.Papers;

  useEffect(() => {
    if (!query) {
      navigate(createLink('/'));
      return;
    }
    analytics.events.search(type);
    dispatch(search({ term: query, pageNum: page, searchType: type }));
  }, [query, page, type, dispatch, navigate]);

  return (
    <SearchTopBar searchStateType={searchState.searchType}>
      <SearchContainer query={query} page={page} type={type} />
    </SearchTopBar>
  );
};
