import { FC, FormEvent, useCallback, useMemo } from 'react';
import {
  Paragraph,
  Flex,
  HeadingOne,
  HeadingTwo,
  Button
} from '../../../atoms';
import { texts } from '../../../text';
import ErrorInfo from '../../../atoms/errorInfo';
import { errorMessages } from '../../../utils/errors';
import {
  CommunityTagsInput,
  InputLetterCount,
  RequiredFieldLabel,
  TopicTagsInput
} from '../../../components/form';
import { RichTextEditor } from '../../../components/richTextEditor';
import { Tag } from '../../../types/topic.type';
import * as Yup from 'yup';
import { useFormik } from 'formik';

interface Props {
  topicTags: Tag[];
  communityTags: Tag[];
  selectedTopicTags: Tag[];
  selectedCommunityTags: Tag[];
  removeTopic: (topic: string) => void;
  filterTopics: (event: FormEvent<HTMLInputElement>) => void;
  addTopic: (elem: string) => void;
  addCommunity: (elem: string) => void;
  removeCommunity: (community: string) => void;
  filterCommunities: (event: FormEvent<HTMLInputElement>) => void;
  setPostName: (name: string) => void;
  setMarkdown: (value: string) => void;
  handleCreate: () => void;
  errors: { [key: string]: string };
  inputTopicsValue: string;
  inputCommunityTags: string;
  postName: string;
  markdown: string;
}

const CreateUserPostSchema = Yup.object().shape({
  postName: Yup.string()
    .required('Required')
    .min(3, 'Title must be at least 3 characters long'),
  markdown: Yup.string()
    .required('Required')
    .min(3, 'Text must be at least 3 characters long'),
  selectedTopicTags: Yup.array().test(
    'topics-or-communities',
    'Topics or Communities must be filled',
    function (value = []) {
      const { selectedCommunityTags = [] } = this.parent;
      return selectedCommunityTags.length > 0 || value.length > 0;
    }
  ),
  selectedCommunityTags: Yup.array().test(
    'topics-or-communities',
    'Topics or Communities must be filled',
    function (value = []) {
      const { selectedTopicTags = [] } = this.parent;
      return selectedTopicTags.length > 0 || value.length > 0;
    }
  )
});

export const CreatePostPage: FC<Props> = ({
  topicTags,
  communityTags,
  selectedTopicTags,
  selectedCommunityTags,
  postName,
  removeTopic,
  filterTopics,
  addTopic,
  addCommunity,
  removeCommunity,
  filterCommunities,
  setPostName,
  setMarkdown,
  handleCreate,
  inputTopicsValue,
  inputCommunityTags,
  errors,
  markdown
}) => {
  const formik = useFormik({
    initialValues: {
      postName: postName,
      markdown: markdown,
      selectedTopicTags: selectedTopicTags,
      selectedCommunityTags: selectedCommunityTags
    },
    validationSchema: CreateUserPostSchema,
    onSubmit: () => {
      handleCreate();
    },
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleNameChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      setPostName(e.target.value);
    };
  }, [formik, setPostName]);

  const handleContentChange = useCallback(
    (value: string) => {
      formik.setFieldValue(
        'markdown',
        new DOMParser()
          .parseFromString(value, 'text/html')
          ?.body.textContent?.trim() ?? ''
      );
      setMarkdown(value);
    },
    [formik, setMarkdown]
  );

  const handleContentBlur = useCallback(
    (e: any) => {
      formik.handleBlur(e);
      formik.setFieldTouched('markdown', true);
    },
    [formik]
  );

  const handleAddTopic = (elem: string) => {
    addTopic(elem);
    formik.setFieldValue('selectedTopicTags', [
      ...formik.values.selectedTopicTags,
      { name: elem, urlKey: elem }
    ]);
  };

  const handleRemoveTopic = (urlKey: string) => {
    removeTopic(urlKey);
    formik.setFieldValue(
      'selectedTopicTags',
      formik.values.selectedTopicTags.filter((tag) => tag.urlKey !== urlKey)
    );
  };

  const handleAddCommunity = (elem: string) => {
    addCommunity(elem);
    formik.setFieldValue('selectedCommunityTags', [
      ...formik.values.selectedCommunityTags,
      { name: elem, urlKey: elem }
    ]);
  };

  const handleRemoveCommunity = (urlKey: string) => {
    removeCommunity(urlKey);
    formik.setFieldValue(
      'selectedCommunityTags',
      formik.values.selectedCommunityTags.filter((tag) => tag.urlKey !== urlKey)
    );
  };

  return (
    <form
      className="w-full mx-auto filters"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
      noValidate
    >
      <main className="page-content max-w-2/3">
        <Flex direction="flex-row w-full">
          <Flex className="m-auto ">
            <HeadingOne color="black">{texts.createPost}</HeadingOne>
            <Paragraph className="mb-0" size="xs" color="black">
              {texts.createNewPostDescription}
            </Paragraph>

            <HeadingTwo className="font-black ">
              <RequiredFieldLabel label={texts.title} />
            </HeadingTwo>
            <InputLetterCount
              value={formik.values.postName}
              setValue={handleNameChange}
              onBlur={formik.handleBlur}
              maxLength={300}
              placeholder={texts.postName}
              name="postName"
              error={
                formik.touched.postName && formik.errors.postName
                  ? formik.errors.postName
                  : undefined
              }
              touched={formik.touched.postName}
            />
            <HeadingTwo>
              <RequiredFieldLabel label={texts.text} />
            </HeadingTwo>
            <div onBlur={handleContentBlur}>
              <RichTextEditor
                value={markdown}
                setValue={handleContentChange}
                maxLength={40000}
                disabled={false}
                error={
                  formik.touched.markdown && formik.errors.markdown
                    ? formik.errors.markdown
                    : undefined
                }
                touched={formik.touched.markdown}
              />
            </div>
            <HeadingTwo>{texts.topicTags}</HeadingTwo>
            <TopicTagsInput
              topicTags={topicTags}
              selectedTopicTags={selectedTopicTags}
              onAddTopic={handleAddTopic}
              filterTopics={filterTopics}
              inputTopicsValue={inputTopicsValue}
              onRemoveTopic={handleRemoveTopic}
              explanationText={texts.postPagetopicTagsExplain}
              maxLimit={15}
              error={
                formik.touched.selectedTopicTags &&
                formik.errors.selectedTopicTags
                  ? (formik.errors.selectedTopicTags as string)
                  : undefined
              }
              touched={formik.touched.selectedTopicTags ? true : false}
              onBlur={() => formik.setFieldTouched('selectedTopicTags', true)}
            />
            <HeadingTwo>{texts.communityTags}</HeadingTwo>
            <CommunityTagsInput
              communityTags={communityTags}
              selectedCommunityTags={selectedCommunityTags}
              onAddCommunity={handleAddCommunity}
              onRemoveCommunity={handleRemoveCommunity}
              filterCommunities={filterCommunities}
              inputCommunityTagsValue={inputCommunityTags}
              explanationText={texts.postPageCommunityTagsExplain}
              maxLimit={10}
              error={
                formik.touched.selectedCommunityTags &&
                formik.errors.selectedCommunityTags
                  ? (formik.errors.selectedCommunityTags as string)
                  : undefined
              }
              touched={formik.touched.selectedCommunityTags ? true : false}
              onBlur={() =>
                formik.setFieldTouched('selectedCommunityTags', true)
              }
            />
            <div className="flex justify-end">
              <Button
                className="mt-4 bg-gradient-to-r from-primaryLight 
                to-primaryDefault inline fitContent desktop:px-20 
                laptop:px-20 px-10 text-small uppercase py-1 font-medium"
                rounded="md"
                size="imd"
                type="submit"
              >
                {texts.createPostButton}
              </Button>
            </div>
            <Flex>
              {Object.keys(errors).map((error: string) => {
                return (
                  <ErrorInfo key={error}>{errorMessages[error]}</ErrorInfo>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </main>
    </form>
  );
};
