import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CommunitiesApi } from '../../../api';
import { CreateCommunity } from './createCommunity';
import { useCommunityForm } from '../hooks/useCommunityForm';
import { createLink } from '../../../utils/helperFunctions';

interface Props {
  closeModal: () => void;
}

export const CreateCommunityContainer: React.FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate();

  const {
    communityName,
    description,
    selectedTopicTags,
    topicTags,
    onRemoveTopic,
    onFilterTopics,
    onAddTopic,
    setCommunityName,
    setDescription,
    errors,
    inputTopicsValue,
    setErrors
  } = useCommunityForm(false);

  const handleCreate = async () => {
    try {
      const topicUrlKeys = selectedTopicTags.map((tag) => tag.urlKey);
      const response = await CommunitiesApi.createCommunity(
        communityName,
        description,
        topicUrlKeys
      );
      const slug = await response.json();
      closeModal();
      if (typeof slug === 'string') {
        navigate(createLink(`/community/${slug}`));
      }
    } catch (error) {
      setErrors((errors) => ({
        ...errors,
        createError: `Error creating community: ${String(error)}`
      }));
    }
  };

  return (
    <CreateCommunity
      topicTags={topicTags}
      selectedTopicTags={selectedTopicTags}
      removeTopic={onRemoveTopic}
      filterTopics={onFilterTopics}
      addTopic={onAddTopic}
      setCommunityName={setCommunityName}
      communityName={communityName}
      setDescription={setDescription}
      description={description}
      handleCreate={handleCreate}
      errors={errors}
      inputTopicsValue={inputTopicsValue}
    />
  );
};
