import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useAppDispatch } from '../../state';
import { AuthApi } from '../../api';
import { Button, Field, Input, Label, Validation } from '../../components/form';
import { analytics } from '../../analytics';
import { useNavigate } from 'react-router-dom';
import { env } from '../../env';
import { useCountdown } from './hooks/useCountDown';
import { PUBLIC_URL_PREFIX } from '../../utils/constants';

interface Props {
  closeModal: () => void;
}

const initialValues = {
  email: '',
  password: ''
};

type FormData = typeof initialValues;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Not a valid email').required('Required'),
  password: Yup.string().required('Required').min(8, 'Minimum of 8 characters')
});

export const Login: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const [disableForgotSubmit, setDisableForgotSubmit] = useState(false);
  const [timer, setTimer] = useState<number | null>(null);

  const countdownTime = useCountdown(timer);

  const formik = useFormik<FormData>({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema
  });

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const openSignUpModal = useCallback(
    () => handleOpenModalHash('signup'),
    [handleOpenModalHash]
  );

  async function handleSubmit(values: FormData) {
    try {
      const user = await AuthApi.login(values.email, values.password);
      dispatch({ type: 'LOGIN_SET_USER', user });
      analytics.events.login();
      props.closeModal();
    } catch (error) {
      await handleLoginError(error);
    }
  }

  const handleLoginError = useCallback(
    (error: any) => {
      const status = error.status;
      const message = error.error.message;
      if (status === 400 || status === 404) {
        setLoginError(message || 'Invalid login credentials.');
      } else {
        setLoginError(
          message || 'Unexpected error occurred. Try again later, please.'
        );
      }
    },
    [setLoginError]
  );

  const handleForgotPasswordError = useCallback(
    async (error: any) => {
      if (error instanceof Response) {
        const data = await error.json();
        const { status, message } = data;
        if (status === 400 || status === 404) {
          setLoginError(message || 'An error occurred. Please try again.');
        } else {
          setLoginError(
            message || 'Unexpected error occurred. Try again later, please.'
          );
          setTimer(15);
        }
      } else {
        setLoginError('Unexpected error occurred. Try again later, please.');
        setTimer(15);
      }
      setDisableForgotSubmit(true);
    },
    [setLoginError, setDisableForgotSubmit, setTimer]
  );

  const handleForgotPassword = useCallback(
    async (values: { email: string }) => {
      setLoginError('');
      try {
        await AuthApi.forgotPassword(
          values.email,
          `${env.apiUrl}/${PUBLIC_URL_PREFIX}`
        );
        setLoginError(`Password reset email has been sent to ${values.email}`);
        setTimer(60);
        setDisableForgotSubmit(true);
      } catch (error) {
        handleForgotPasswordError(error);
      }
    },
    [setLoginError, setDisableForgotSubmit, setTimer, handleForgotPasswordError]
  );

  // Enable button and clear the errors when countdownTime is null
  useEffect(() => {
    if (countdownTime === null) {
      setTimer(null);
      setLoginError('');
      setDisableForgotSubmit(false);
    }
  }, [countdownTime]);

  // Reset disableForgotSubmit when email changes
  useEffect(() => {
    setTimer(null);
    setLoginError('');
    setDisableForgotSubmit(false);
  }, [formik.values.email]);

  const buttonTitle =
    countdownTime !== null && countdownTime > 0
      ? `Re-send email (${countdownTime})`
      : 'Forgot Password';

  return (
    <form
      className="w-full mx-auto mt-10 filters"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex-col mb-4 tablet:flex tablet:flex-row">
        <Field className="tablet:mr-2">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="ex. John"
          />
          {formik.errors.email && formik.touched.email && (
            <Validation message={formik.errors.email} />
          )}
        </Field>
        <Field className="tablet:ml-2">
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="********"
          />
          {formik.errors.password && formik.touched.password && (
            <Validation message={formik.errors.password} />
          )}
        </Field>
      </div>
      {loginError && (
        <div className="text-primary-500 mb-4 text-center ">{loginError}</div>
      )}
      <div className="flex align-center items-center flex-col justify-center desktop:flex-row laptop:flex-row tablet:flex-row">
        <div className="flex items-baseline justify-center mr-0 mb-2 desktop:mr-4 laptop:mr-4 tablet:mr-4 desktop:mb-0 laptop:mb-0 tablet:mb-0 ">
          <Button type="submit" className="px-2 py-2 w-48">
            Sign In
          </Button>
        </div>
        <div className="tablet:mt-0 laptop:mt-0 desktop:mt-0 mt-0 flex items-baseline justify-center">
          <Button
            outline={true}
            disabled={
              !formik.values.email ||
              !!formik.errors.email ||
              disableForgotSubmit
            }
            onClick={() => handleForgotPassword(formik.values)}
            className="px-2 py-2 w-48"
          >
            {buttonTitle}
          </Button>
        </div>
      </div>
      <p className="mt-8 text-sm text-center">
        Don&apos;t have an account?{' '}
        <button
          className="text-primary-500 font-semibold"
          onClick={openSignUpModal}
          type="button"
        >
          Sign up here
        </button>
      </p>
    </form>
  );
};
