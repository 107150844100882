import { FC } from 'react';

import { Loading } from '../../components/core';
import { Pager } from './pager';
import { useTopicPapersPageContainer } from './useTopicsPapersPageContainer';
import { Previewv2 } from '../../components/paper';

interface Props {}

export const TopicPapersPage: FC<Props> = () => {
  const { loading, papers, getPageLink } = useTopicPapersPageContainer();

  if (loading || !papers) {
    return <Loading />;
  }

  return (
    <>
      {papers.count === 0 && (
        <div>
          Papers for this topic are being loading, please come back soon.
        </div>
      )}
      {papers.page &&
        papers.page.map((p) => (
          <Previewv2 key={p.id} paper={p} trackingLabel="topic" />
        ))}
      {papers.numPages && papers.pageNum && papers.numPages > 1 && (
        <div className="mt-5">
          <Pager
            pageNum={papers.pageNum}
            numPages={papers.numPages}
            getPageLink={getPageLink}
          />
        </div>
      )}
    </>
  );
};
