import classNames from 'classnames';
import { ChangeEvent, useState } from 'react';
import { ApiDatabaseCounts } from '../../api/oipub-api';
import { Search } from '../../svgs';
import { texts } from '../../text';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { ISearchType } from '../../types/search.type';
import { getSearchPlaceholder } from './getSearchPlaceholder';
export interface Props {
  initialText?: string;
  initialType?: ISearchType;
  onSearch(term: string, type: ISearchType): void;
  counts?: ApiDatabaseCounts;
}

export const SearchField3 = ({
  initialType = ISearchType.Papers,
  onSearch = () => {},
  counts
}: Props) => {
  const [type, setType] = useState(initialType);
  const location = useLocation();
  const queryString = qs.parse(location.search.substring(1));
  const query = queryString['q'] as string;
  const [text, setText] = useState(query);
  const FilterSchema = Yup.object().shape({
    type: Yup.string().nullable(),
    search: Yup.string().nullable()
  });

  const placeholder = getSearchPlaceholder(type, counts || {});

  const initialValues = {
    type: 'papers',
    search: query || ''
  };

  const onChangeDropDown: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const newSearchType = event.target.value;
    if (newSearchType) setType(newSearchType as ISearchType);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    handleSubmit: any
  ) => {
    if (e.key === 'Enter') {
      if (text !== '') handleSubmit();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    setText(e.target.value);
    setFieldValue('search', e.target.value);
  };

  return (
    <div
      className={classNames(
        'flex h-12 w-full max-w-3xl text-sm bg-whiteBorder  p-1 rounded gap-3 border-2 main-search border-transparent'
      )}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={FilterSchema}
        onSubmit={(values) => {
          onSearch(values.search || query, type);
        }}
      >
        {({ setFieldValue, handleSubmit }) => (
          <Form className="w-full flex">
            <Field
              as="select"
              name="type"
              value={type}
              className="rounded border border-primaryDefault tablet:pl-4 tablet:pr-6 pr-2 pl-2 py-2 text-primaryDefault focus:outline-none"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setFieldValue('type', e.target.value);
                onChangeDropDown(e);
              }}
            >
              <option value={ISearchType.Papers}>{ISearchType.Papers}</option>
              <option value={ISearchType.Topics}>{ISearchType.Topics}</option>
              <option value={ISearchType.Communities}>
                {ISearchType.Communities}
              </option>
            </Field>
            <Field
              className="flex-grow pl-3 inline-block focus:outline-none bg-transparent placeholder-primaryDark focus:placeholder-grayBorder placeholder-grayBorder w-full"
              type="text"
              name="search"
              placeholder={placeholder}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown(e, handleSubmit)
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, setFieldValue)
              }
            />
            <button
              type="submit"
              disabled={!text}
              className="flex items-center bg-gradient-to-r from-primaryLight to-primaryDefault p-3 tablet:px-10 px-4  gap-1 rounded opacity-100 hover:opacity-50 focus:outline-none"
            >
              <span className="text-white">{texts.search}</span>
              <div className="w-4 text-white">
                <Search />
              </div>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
