import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TopicApi } from '../../api';
import { ApiPaperPaged } from '../../api/oipub-api';
import { createLink } from '../../utils/helperFunctions';

export const useTopicPapersPageContainer = () => {
  const { name, page: strPage } = useParams();

  const [papers, setPapers] = useState<ApiPaperPaged>({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const page = Number(strPage || '1');

  // Redirect if invalid page
  useEffect(() => {
    if (isNaN(page)) {
      navigate(createLink(`/topic/${name}`));
    }
  }, [name, navigate, page]);

  const getPageLink = useCallback(
    (page: number) => createLink(`/topic/${name}/papers/${page}`),
    [name]
  );

  useEffect(() => {
    if (!loading && (papers === null || papers.pageNum !== page)) {
      (async function () {
        setLoading(true);
        try {
          const results = await TopicApi.getPapers(name!, page);
          setPapers(results);
          if (results.pageNum && results.pageNum !== page) {
            // Past max page number
            navigate(getPageLink(results.pageNum));
          }
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [getPageLink, loading, name, navigate, page, papers]);

  return {
    loading,
    papers,
    getPageLink
  };
};
