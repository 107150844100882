import { Loading } from '../../components/core';
import { useAppSelector } from '../../state';
import {
  selectHubs,
  selectPapers,
  selectRequestAbortedForNew,
  selectSearchError,
  selectSearchLoading,
  selectSearchPageNum,
  selectSearchTerm,
  selectSearchType
} from '../../state/selectors/searchSelector';
import { ISearchType } from '../../types/search.type';

interface SearchStatusHandlerProps {
  query: string;
  page: number;
  type: ISearchType;
  children: React.ReactNode;
}

export const SearchStatusHandler: React.FC<SearchStatusHandlerProps> = ({
  query,
  page,
  type,
  children
}: SearchStatusHandlerProps) => {
  const searchTerm = useAppSelector(selectSearchTerm);
  const searchPageNum = useAppSelector(selectSearchPageNum);
  const searchType = useAppSelector(selectSearchType);
  const searchError = useAppSelector(selectSearchError);
  const searchLoading = useAppSelector(selectSearchLoading);
  const requestAbortedForNew = useAppSelector(selectRequestAbortedForNew);
  const papers = useAppSelector(selectPapers);
  const hubs = useAppSelector(selectHubs);

  const isOutdated =
    searchTerm !== query || searchPageNum !== page || searchType !== type;

  if (searchLoading || isOutdated || requestAbortedForNew) {
    return <Loading className="mt-5" />;
  }

  if (searchError && !requestAbortedForNew) {
    return (
      <div className="w-full max-w-3xl flex mt-5 px-4 justify-center items-center flex-grow mx-auto">
        We couldn&apos;t process your search at this minute. We&apos;re actively
        working on improving this site and will iron out any issues. In the
        meantime, please search for something different.
      </div>
    );
  }

  if (
    (!hubs?.page || hubs.page.length < 1) &&
    (!papers?.page || papers.page.length < 1) &&
    !searchError &&
    !searchLoading
  ) {
    return (
      <div className="w-full max-w-3xl flex justify-center items-center flex-grow mx-auto mt-6">
        No results found
      </div>
    );
  }

  return <>{children}</>;
};
