import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import { SearchTypes } from '../../api/oipub-api';

import { NavigateFunction } from 'react-router-dom';
import { abortRequestForNew, setQuery } from '../slices/searchReducer';
import { env } from '../../env';
import { IPaperPaged } from '../../types/papers.type';
import { ICommonHubPaged } from '../../types/hubs.type';
import { ISearchType } from '../../types/search.type';
import { AppState } from '../store';
import { createLink } from '../../utils/helperFunctions';

export const getSearchUrl = (term: string, type: ISearchType, page: number) => {
  const params = qs.stringify({
    q: term,
    t: type === ISearchType.Papers ? undefined : type,
    p: !page || page <= 1 ? undefined : page
  });
  return createLink(type + `/search?${params}`);
};

export const searchPapers = createAsyncThunk<
  IPaperPaged,
  { term: string; page: number; abortController: AbortController }
>(
  'get/searchPapers',
  async ({ term, page, abortController }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/feed/search?q=${encodeURIComponent(term)}&p=${page}`;

    const signal = abortController.signal;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        return rejectWithValue('Failed to fetch papers');
      }

      const data: IPaperPaged = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const searchTopicsCommunities = createAsyncThunk<
  ICommonHubPaged,
  {
    term: string;
    page: number;
    searchType: ISearchType;
    abortController: AbortController;
  }
>(
  'get/searchTopicsCommunities',
  async ({ term, page, searchType, abortController }, { rejectWithValue }) => {
    let apiType;
    switch (searchType) {
      case ISearchType.Topics:
        apiType = SearchTypes.Value1;
        break;
      case ISearchType.Communities:
        apiType = SearchTypes.Value2;
        break;
    }

    const url = `${env.apiUrl}/api/v1/hubs/search?q=${encodeURIComponent(
      term
    )}&p=${page}&t=${apiType}`;

    const signal = abortController.signal;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (!response.ok) {
        return rejectWithValue('Failed to fetch topics/communities');
      }

      const data: ICommonHubPaged = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

let currentAbortController: AbortController | null = null;

export const search = createAsyncThunk<
  void,
  {
    term: string;
    pageNum: number;
    searchType: ISearchType;
  },
  { rejectValue: string; state: AppState }
>(
  'get/fetchSearchResults',
  async ({ term, pageNum, searchType }, { dispatch }) => {
    // Abort the previous request if a new one starts
    if (currentAbortController) {
      dispatch(abortRequestForNew());
      currentAbortController.abort();
    }

    // Create a new AbortController for the new request
    currentAbortController = new AbortController();

    // Set query and clear previous results
    dispatch(setQuery({ term, pageNum, searchType }));

    try {
      if (searchType === ISearchType.Papers) {
        await dispatch(
          searchPapers({
            term,
            page: pageNum,
            abortController: currentAbortController
          })
        ).unwrap();
      } else {
        await dispatch(
          searchTopicsCommunities({
            term,
            page: pageNum,
            searchType,
            abortController: currentAbortController
          })
        ).unwrap();
      }
    } catch (error) {
      throw error;
    } finally {
      // Clear the current AbortController after the request completes or is aborted
      currentAbortController = null;
    }
  }
);

export const changePage =
  (
    query: string,
    newPage: number,
    type: ISearchType,
    pathname: string,
    navigate: NavigateFunction
  ) =>
  async () => {
    try {
      const queryString = qs.stringify({
        q: query,
        t: type === ISearchType.Papers ? undefined : type,
        p: !newPage || newPage <= 1 ? undefined : newPage
      });

      navigate({
        pathname: pathname,
        search: `?${queryString}`
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error changing page:', error);
    }
  };
