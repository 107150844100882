import { FC, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, Field, Input, Label, Validation } from '../../components/form';
import { AuthApi } from '../../api';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { passwordValidationSchema } from '../register/register';
import { QuestionMark, VisibilityHidden, VisibilityShow } from '../../svgs';
import { Tooltip } from '../../components/core/tooltip';
import { NotificationModalWrapper } from '../../components/modals';
import { createLink } from '../../utils/helperFunctions';

interface Props {}

const initialValues = {
  password: '',
  confirmPassword: '',
  showPassword: false
};

type FormData = typeof initialValues;

const validationSchema = Yup.object().shape({
  password: passwordValidationSchema,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .when('showPassword', {
      is: false,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.notRequired()
    })
});

const passwordRequirements = (
  <div className="password-requirements">
    Password must:
    <ul>
      <li>Be 8-64 characters long</li>
      <li>Have at least one uppercase letter (A-Z)</li>
      <li>Have at least one lowercase letter (a-z)</li>
      <li>Contain at least one number (0-9)</li>
      <li>
        Have at least one special character (e.g., !@#$%^&*()_+-=[]{}
        |;&apos;:&quot;,.&lt;&gt;?)
      </li>
    </ul>
  </div>
);

export const ResetPasswordPage: FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token') || '';
  const email = queryParams.get('email') || '';

  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] =
    useState<React.ReactNode>('');
  const [countdown, setCountdown] = useState(5);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    const newShowPassword = !showPassword;
    setShowPassword(newShowPassword);
    formik.setFieldValue('showPassword', newShowPassword);
  };

  const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
  };

  useEffect(() => {
    if (!token || !email) {
      navigate(createLink('communities/#login'), { replace: true });
    }
  }, [token, email, navigate]);

  const startCountdown = useCallback(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          navigate(createLink('communities/#login'));
        }
        return prev - 1;
      });
    }, 1000);
  }, [navigate]);

  useEffect(() => {
    if (countdown > 0) {
      setNotificationMessage(
        <>
          You will be redirected to the home page in {countdown} seconds.{' '}
          <Link to={createLink('communities/#login')}>
            Click here to go to the home page right now.
          </Link>
        </>
      );
    }
  }, [countdown, navigate]);

  async function handleSubmit(values: FormData) {
    try {
      await AuthApi.resetPassword(token, email, values.password);
      setNotificationTitle('Success');
      setNotificationOpen(true);
      startCountdown();
    } catch (error) {
      let errorMessage =
        'There was a problem resetting your password. Please try again.';
      if (error instanceof Response) {
        // If the error is an instance of Response, handle it accordingly
        const errorData = await error.json();
        if (errorData.errors && Array.isArray(errorData.errors)) {
          errorMessage = errorData.errors.join(' ');
          errorMessage += ' Please try again.';
        }
      }
      setNotificationTitle('Error');
      setNotificationMessage(errorMessage);
      setNotificationOpen(true);
    }
  }

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const formik = useFormik<FormData>({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema
  });

  return (
    <div>
      <form
        className="w-full max-w-md mx-auto mt-16 register-form"
        onSubmit={formik.handleSubmit}
      >
        <Field className="">
          <div className="flex justify-between">
            <Label htmlFor="password">Password</Label>
            <Tooltip
              className="tooltipPassword tooltipResetPassword"
              text={passwordRequirements}
            >
              <QuestionMark />
            </Tooltip>
          </div>
          <div className="relative">
            <Input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              onBlur={handlePasswordBlur}
              value={formik.values.password}
              className={`pr-5 ${
                formik.touched.password && formik.errors.password
                  ? 'border-error-500'
                  : ''
              } ${formik.touched.password && !formik.errors.password ? 'border-green-500' : ''}`}
              placeholder="********"
            />
            <button
              type="button"
              className="absolute right-0 showHideBtn"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityShow /> : <VisibilityHidden />}
            </button>
          </div>
          {formik.errors.password && formik.touched.password && (
            <Validation message={formik.errors.password} />
          )}
        </Field>
        {!showPassword && (
          <Field className="">
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              className={`${
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? 'border-error-500'
                  : ''
              } ${formik.touched.confirmPassword && !formik.errors.confirmPassword ? 'border-green-500' : ''}`}
              placeholder="********"
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <Validation message={formik.errors.confirmPassword} />
              )}
          </Field>
        )}
        <div className="flex justify-center">
          <Button type="submit" className="px-8 py-2">
            Reset Password
          </Button>
        </div>
      </form>
      <div>
        <NotificationModalWrapper
          isOpen={isNotificationOpen}
          title={notificationTitle}
          message={notificationMessage}
          onClose={handleNotificationClose}
        />
      </div>
    </div>
  );
};
