import { RenderHTML } from '../../components/paper/render-html';

export interface Props {
  title: string;
  id: string | null | undefined;
}

export const Furniturev2: React.FC<Props> = (props: Props) => {
  const { title } = props;

  return (
    <div className="mb-3">
      <div className="items-baseline">
        <h1 className="mb-3 text-xl font-bold leading-snug">
          <RenderHTML htmlContent={title} />
        </h1>
        <div className="text-primary-500 text-sm flex">#Pa[{props.id}]</div>
      </div>
    </div>
  );
};
