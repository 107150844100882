import { AppState } from '../store';

export const selectSearchTerm = (state: AppState) => state.search.term;
export const selectSearchPageNum = (state: AppState) => state.search.pageNum;
export const selectSearchType = (state: AppState) => state.search.searchType;
export const selectSearchError = (state: AppState) => state.search.error;
export const selectSearchLoading = (state: AppState) => state.search.loading;
export const selectRequestAbortedForNew = (state: AppState) =>
  state.search.requestAbortedForNew;
export const selectPapers = (state: AppState) => state.search.papers;
export const selectHubs = (state: AppState) => state.search.hubs;
