import { combineReducers, compose, configureStore } from '@reduxjs/toolkit';
import {
  LibraryActions,
  LoginActions,
  UiActions,
  SortingActions,
  ViewModeAction
} from './actions';
import { libraryReducer } from './slices/libraryReducer';
import { loginReducer } from './slices/loginReducer';
import { uiReducer } from './slices/uiReducer';
import modalReducer from './slices/modalsReducer';
import sortingReducer from './slices/sortingReducer';
import radioStateReducer from './slices/tabsReducer';
import viewReducer from './slices/viewmodeReducer';
import communityReducer from './slices/communityReducer';
import { userPostReducer } from './slices/userPostReducer';
import { userPostsReducer } from './slices/userPostsReducer';
import userReducer from './slices/userReducer';
import commentsReducer from './slices/commentsReducer';
import { paperReducer } from './slices/paperReducer';
import { discussionsReducer } from './slices/discussionsReducer';
import { papersReducer } from './slices/papersReducer';
import { communitiesReducer } from './slices/communitiesReducer';
import { topicsReducer } from './slices/topicsReducer';
import { topicReducer } from './slices/topicReducer';
import searchReducer from './slices/searchReducer';
import { papersFilterReducer } from './slices/filterPapersReducer';

export type AppActions =
  | LibraryActions
  | LoginActions
  | UiActions
  | SortingActions
  | ViewModeAction;

const appReducer = combineReducers({
  library: libraryReducer,
  login: loginReducer,
  search: searchReducer,
  ui: uiReducer,
  filterPapers: papersFilterReducer,
  modal: modalReducer,
  sort: sortingReducer,
  tabs: radioStateReducer,
  viewmode: viewReducer,
  community: communityReducer,
  communities: communitiesReducer,
  userPost: userPostReducer,
  userPosts: userPostsReducer,
  user: userReducer,
  comments: commentsReducer,
  paper: paperReducer,
  papers: papersReducer,
  discussions: discussionsReducer,
  topic: topicReducer,
  topics: topicsReducer
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

export const store = configureStore({
  reducer: appReducer
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
