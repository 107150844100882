import React from 'react';
import { IComment } from '../../types/comment.type';
import { DiscussionType } from './discussion-type';
import { Comment } from './comment';

interface CommentsProps {
  comments: IComment[];
  onReply: (text: string, parentId: number) => Promise<void>;
  onEdit: (commentId: number, newText: string) => Promise<void>;
  onDelete: (commentId: number) => Promise<void>;
  type: DiscussionType;
}

export const Comments: React.FC<CommentsProps> = ({
  comments,
  onReply,
  onEdit,
  onDelete,
  type
}) => {
  return (
    <div>
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          onReply={onReply}
          onEdit={onEdit}
          onDelete={onDelete}
          type={type}
        />
      ))}
    </div>
  );
};
