// If a button should be disabled, add "_disabled" to the value of its key
export const DISCUSSION_SORT_METHODS = {
  LAST_COMMENT_DESC: 'last_comment_desc',
  NUM_COMMENTS_DESC: 'num_comments_desc',
  NUM_RECENT_COMMENTS_DESC: 'num_recent_comments_desc',
  LAST_POST_DESC: 'last_post_desc'
} as const;

export const TOPIC_SORT_METHODS = {
  LAST_COMMENT_DESC: 'last_comment_desc',
  NUM_COMMENTS_DESC: 'num_comments_desc',
  NUM_RECENT_COMMENTS_DESC: 'num_recent_comments_desc',
  PAPERS_COUNT_DESC: 'papers_count_desc',
  POSTS_COUNT_DESC: 'posts_count_desc'
} as const;

export const COMMUNITY_SORT_METHODS = {
  ALPHABETICAL_ASC: 'alphabetical_asc',
  ALPHABETICAL_DESC: 'alphabetical_desc',
  LAST_COMMENT_DESC: 'last_comment_desc',
  NUM_COMMENTS_DESC: 'num_comments_desc',
  NUM_RECENT_COMMENTS_DESC: 'num_recent_comments_desc',
  PAPERS_COUNT_DESC: 'papers_count_desc',
  POSTS_COUNT_DESC: 'posts_count_desc'
} as const;

export const COMMENT_SORT_METHODS = {
  CREATED_ASC: 'created_asc',
  CREATED_DESC: 'created_desc'
} as const;

export const PAPER_COMMUNITY_SORT_METHODS = {
  COMMUNITY_PAPERS_HOT: 'community_papers_hot',
  COMMUNITY_PAPERS_LATEST: 'community_papers_latest',
  COMMUNITY_PAPERS_TOP: 'community_papers_top'
} as const;

export const PAPER_TOPIC_SORT_METHODS = {
  TOPIC_PAPERS_HOT: 'topic_papers_hot',
  TOPIC_PAPERS_LATEST: 'topic_papers_latest',
  TOPIC_PAPERS_TOP: 'topic_papers_top'
} as const;

export const USERPOSTS_SORT_METHODS = {
  LAST_COMMENT_DESC: 'last_comment_desc',
  NUM_COMMENTS_DESC: 'num_comments_desc',
  NUM_RECENT_COMMENTS_DESC: 'num_recent_comments_desc'
} as const;

export const SORT_METHOD_DISPLAY_NAMES = {
  alphabetical_asc: 'Alphabetical ↑',
  alphabetical_desc: 'Alphabetical ↓',
  last_comment_desc: 'Latest Comment',
  num_comments_desc: 'Most Comments',
  num_recent_comments_desc: 'Comment Activity',
  created_asc: 'Most Recent',
  created_desc: 'Oldest',
  papers_count_desc: 'Most Papers',
  posts_count_desc: 'Most Discussions',
  last_post_desc: 'Latest Discussion',
  community_papers_relevant_scores_desc: 'Relevance',
  community_papers_top: 'Top',
  community_papers_latest: 'Latest',
  community_papers_trending: 'Trending',
  community_papers_hot: 'Hot',
  topic_papers_top: 'Top',
  topic_papers_latest: 'Latest',
  topic_papers_relevant_scores_desc: 'Relevance',
  topic_papers_trending: 'Trending',
  topic_papers_hot: 'Hot'
};

export type DiscussionSortMethod =
  (typeof DISCUSSION_SORT_METHODS)[keyof typeof DISCUSSION_SORT_METHODS];
export type PaperCommunitySortMethod =
  (typeof PAPER_COMMUNITY_SORT_METHODS)[keyof typeof PAPER_COMMUNITY_SORT_METHODS];
export type PaperTopicSortMethod =
  (typeof PAPER_TOPIC_SORT_METHODS)[keyof typeof PAPER_TOPIC_SORT_METHODS];
export type TopicSortMethod =
  (typeof TOPIC_SORT_METHODS)[keyof typeof TOPIC_SORT_METHODS];
export type CommunitySortMethod =
  (typeof COMMUNITY_SORT_METHODS)[keyof typeof COMMUNITY_SORT_METHODS];
export type CommentSortMethod =
  (typeof COMMENT_SORT_METHODS)[keyof typeof COMMENT_SORT_METHODS];
export type userPostsSortMethod =
  (typeof USERPOSTS_SORT_METHODS)[keyof typeof USERPOSTS_SORT_METHODS];
