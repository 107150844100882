import { FC, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUserPostPreview } from '../../api';
import { DatePost } from '../../svgs/DatePost';
import { CommentPost } from '../../svgs/CommentPost';
import { ShareButton } from '../../components/share';
import { formatDate } from '../../utils/userPostHelper';
import { useCommentCount } from '../../components/discussion/hooks/useCommentCount';
import { UserPostDiscussions } from '../../svgs/UserPostDiscussions';
import { thousandSeparator } from '../../utils/numbers-formatter';
import { IComment } from '../../types/comment.type';
import { createLink } from '../../utils/helperFunctions';

interface Props {
  discussion: ApiUserPostPreview;
  // viewState: string;
  comments?: IComment[] | null;
  isDiscussion?: boolean;
  showThreeItems?: boolean;
}

export const DiscussionSummaryv2: FC<Props> = ({
  discussion,
  comments,
  isDiscussion,
  showThreeItems
}) => {
  const navigate = useNavigate();
  const postLink = createLink(`/post/${discussion.id}`);

  // const handleClick = () => {
  //   navigate(postLink);
  // };

  const currentUrl = window.location.origin;
  const fullUrl = currentUrl + postLink;

  const countFromHook = useCommentCount(comments || discussion.comments);
  const renderedTopicsKeywords: JSX.Element[] = [];
  const showTopicsKeywordsCount = 2;

  const extractPreviewFromHTML = (
    htmlString: string,
    maxLength: number = 200
  ): string => {
    let truncatedHtml = htmlString.slice(0, maxLength);
    // This regex matches a partial tag starting with "<" that does not close with ">"
    truncatedHtml = truncatedHtml.replace(/<[^>]*$/, '');
    const doc = new DOMParser().parseFromString(truncatedHtml, 'text/html');
    return doc.body.innerHTML.trim();
  };

  const previewContent = extractPreviewFromHTML(discussion.markdown || '');

  const handleTopicTagClick = useCallback(
    (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      urlKey: string | null | undefined
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (urlKey) {
        navigate(createLink(`/topic/${urlKey}`));
      }
    },
    [navigate]
  );

  if (discussion.tags) {
    let i = 0;
    for (const tag of discussion.tags.slice(0, showTopicsKeywordsCount)) {
      if (renderedTopicsKeywords.length < showTopicsKeywordsCount) {
        if (tag && tag.urlKey && tag.id) {
          renderedTopicsKeywords.push(
            <span
              role="button"
              onClick={(e) => handleTopicTagClick(e, tag.urlKey)}
              className="text-ssm mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline leading-none mr-1 mb-2 transition-colors duration-200 text-center"
              key={tag.id}
              style={{ cursor: 'pointer' }}
            >
              {tag.name}
              {i < discussion.tags.length - 1 ? ', ' : ' '}
            </span>
          );
          i++;
        }
      }
    }
  }

  const numOfTopicsKeywords = discussion.tags ? discussion.tags.length : 0;
  return (
    <>
      <div
        className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 
      block w-full max-w-3xl mx-auto transition-colors duration-200 border-b"
      >
        <Link
          to={postLink}
          className={`item w-full ${isDiscussion ? 'discussion-class' : ''}`}
        >
          {isDiscussion && (
            <div className="disc_item_icon">
              <UserPostDiscussions className="discussion_icon userpost_discussion_icon" />
            </div>
          )}
          <div className="itemTitle font-bold flex mb-3 cursor-pointer">
            <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight">
              {discussion.title}
              {/* {renderedDiscussionBadge} */}
            </div>
          </div>
          {/* <div className="authorDate color-itemContent font-roboto text-ssm mb-3">
            Published by&nbsp;
            <span className="text-yellow opacity-1">{discussion.userId}</span>
          </div> */}
          <div className="ql-snow">
            <div
              className="itemContent color-itemContent text-itemContent mb-5 ql-editor ql-editor--show"
              dangerouslySetInnerHTML={{ __html: previewContent || '' }}
            ></div>
          </div>
          <hr />
          <div
            className={`flex justify-between mt-4 ${showThreeItems ? 'flex-col-reverse' : ''}`}
          >
            <div className="itemActions items-center flex justify-between text-black">
              {discussion.createdUtc ? (
                <div className="itemActionDate">
                  <div className="flex items-center">
                    <DatePost />
                    <span className="text-half ml-1">
                      {formatDate(discussion.createdUtc)}
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="itemActionComments">
                <div className="flex items-center">
                  <CommentPost className="flex-shrink-0" />
                  <span className="text-half w-full ml-1">
                    {countFromHook || '0'}
                  </span>
                </div>
              </div>
              <div className="itemActionShare">
                <div className="flex items-center">
                  <ShareButton url={fullUrl} />
                </div>
              </div>
            </div>
            <div
              className={`authorLink font-roboto text-half ${showThreeItems ? 'mb-4' : ''}`}
            >
              {renderedTopicsKeywords}
              {numOfTopicsKeywords > 2 && (
                <span className="text-gray-400">
                  + {thousandSeparator(numOfTopicsKeywords - 2)} {'more'}
                </span>
              )}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
