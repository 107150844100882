import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../state';
import { SearchPagination } from './search-pagination';
import { Previewv2 } from '../../components/paper';
import { ViewLine } from '../../svgs/ViewLine';
import { ViewThreeInLine } from '../../svgs/ViewThreeInLine';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { HubPreviewv2 } from '../../components/hub';
import { changePage } from '../../state/actions/searchActions';
import { ISearchType } from '../../types/search.type';

import { selectHubs, selectPapers } from '../../state/selectors/searchSelector';
import { SearchStatusHandler } from './searchStatusHandler';
import { createLink } from '../../utils/helperFunctions';

export interface Props {
  query: string;
  page: number;
  type: ISearchType;
}

export const SearchContainer: React.FC<Props> = ({ query, page, type }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const [showThreeItems, setShowThreeItems] = useState(false);

  const papers = useAppSelector(selectPapers);
  const hubs = useAppSelector(selectHubs);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(changePage(query, newPage, type, location.pathname, navigate));
    },
    [dispatch, query, type, location.pathname, navigate]
  );

  return (
    <SearchStatusHandler query={query} page={page} type={type}>
      <div className="search-results mt-2">
        <div className="flex w-full flex-col">
          <div className="w-full px-4">
            <div className="max-w-3xl flex w-full ml-auto mr-auto">
              <div
                className="flex flex-row justify-center 
              laptop:justify-start desktop:justify-start tablet:justify-start flex-wrap pt-2 "
              >
                {/* <Button className="py-2 w-40 mr-1 mb-1">Relevance</Button> */}
              </div>
            </div>
          </div>
          <div className="flex justify-center  w-full pt-0 mb-2 px-4">
            <div className="flex w-full  flex justify-end max-w-3xl">
              <div className="flex justify-end btnsOrder ">
                <button onClick={() => setShowThreeItems(false)}>
                  <ViewLine />
                </button>
                <button onClick={() => setShowThreeItems(true)}>
                  <ViewThreeInLine />
                </button>
              </div>
            </div>
          </div>
          <div className=" flex flex justify-center h-full  w-full py-3 mb-2 px-4">
            <div className="flex w-full flex  max-w-3xl ">
              <div className="flex flex-wrap  w-full">
                {((papers?.page && papers.page.length > 0) ||
                  (hubs?.page && hubs.page.length > 0)) && (
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{
                      350: 1,
                      600: showThreeItems ? 3 : 1,
                      900: showThreeItems ? 3 : 1
                    }}
                    className="w-full"
                  >
                    <Masonry gutter="14px">
                      {type === ISearchType.Papers
                        ? papers?.page?.map((paper) => (
                            <Previewv2 key={paper.id} paper={paper} />
                          ))
                        : hubs?.page?.map((hub) => (
                            <HubPreviewv2
                              key={hub.refId}
                              hub={hub}
                              title={hub.name || ''}
                              numPapers={1}
                              numDiscussions={1}
                              url={
                                type === ISearchType.Topics
                                  ? createLink(`/topic/${hub.urlKey}`)
                                  : createLink(`/community/${hub.urlKey}`)
                              }
                            />
                          ))}
                    </Masonry>
                  </ResponsiveMasonry>
                )}
              </div>
            </div>
          </div>
        </div>
        {page && (
          <div className="max-w-3xl mx-auto mt-8">
            <SearchPagination
              currentPage={page}
              lastPage={
                type === ISearchType.Papers
                  ? papers?.numPages ?? 1
                  : hubs?.numPages ?? 1
              }
              numOfMobilPages={5}
              onPageChange={(page) => handlePageChange(page)}
            />
          </div>
        )}
      </div>
    </SearchStatusHandler>
  );
};
