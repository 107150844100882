import { Furniturev2 } from './paper-furniture';
import { Abstractv2 } from './paper-abstract';
import { RenderHTML } from '../../components/paper/render-html';
import { useAppSelector } from '../../state';
import {
  selectComments,
  selectCommentsLoading
} from '../../state/selectors/commentsSelector';
import { Loading } from '../../components/core';
import { useCommentsPaper } from '../../components/discussion/hooks/useCommentsPaper';
import { Discussion } from '../../components/discussion';
import { texts } from '../../text';

export const PaperPageContainer: React.FC = () => {
  const { handleReply, handleDelete, handleEdit } = useCommentsPaper();

  const paper = useAppSelector((state) => state.paper.paper);
  const comments = useAppSelector(selectComments);
  const loading = useAppSelector(selectCommentsLoading);

  return (
    <div className="">
      <div className="paperBorder"></div>
      <main className="pt-8 pb-6 tablet:px-12 px-6">
        {paper && paper.title && paper.id && (
          <Furniturev2 title={paper.title} id={paper.id} />
        )}
        {/* <div className="authorDate color-itemContent font-roboto text-ssm mb-3">
          Published by&nbsp;
          <span className="text-yellow opacity-1">{props.paper.publishingInfo?.publisher}</span>
        </div> */}
        <div className="withStyles block mt-6">
          <div className=" tablet:order-first order-last page-content">
            {paper?.abstract ? (
              <Abstractv2>
                <RenderHTML htmlContent={paper?.abstract} />
              </Abstractv2>
            ) : (
              <div className="text-gray-600">
                <RenderHTML htmlContent={texts.papersWithoutAbstract} />
              </div>
            )}
          </div>
        </div>
      </main>
      {loading ? (
        <Loading />
      ) : (
        <Discussion
          type="paper"
          comments={comments}
          onReply={handleReply}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};
