import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ICommunitySummaryPaged } from '../../types/communities.type';

export const getCommunitiesData = createAsyncThunk<
  ICommunitySummaryPaged,
  { page: number; pageSize: number; communityType: number; sortMethod?: string }
>(
  'get/getCommunitiesData',
  async (
    { page, pageSize, communityType, sortMethod },
    { rejectWithValue }
  ) => {
    const query =
      `communityType=${communityType}&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/communities?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch communities');
      }

      const data: ICommunitySummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch communities');
    }
  }
);

export const getLandingCommunitiesData = createAsyncThunk<
  ICommunitySummaryPaged,
  { page: number; pageSize: number; communityType: number; sortMethod?: string }
>(
  'get/getLandingCommunitiesData',
  async (
    { page, pageSize, communityType, sortMethod },
    { rejectWithValue }
  ) => {
    const query =
      `communityType=${communityType}&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/communities?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch communities');
      }

      const data: ICommunitySummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch communities');
    }
  }
);

export const searchCommunities = createAsyncThunk<
  ICommunitySummaryPaged,
  { page: number; pageSize: number; query: string }
>(
  'get/searchCommunities',
  async ({ page, pageSize, query }, { rejectWithValue }) => {
    const queryString = `q=${query}&p=${page}&s=${pageSize}`;
    const url = `${env.apiUrl}/api/v1/communities/search?${queryString}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to search communities');
      }

      const data: ICommunitySummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to search communities');
    }
  }
);
