import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaperPaged } from '../../types/papers.type';
import { IPaperFilterData } from '../../types/filter.type';
import { fetchCommunityPapersData, fetchTopicPapersData } from '../actions';

const initialPaperFilterData: IPaperFilterData = {
  title: '',
  publishedDateFrom: null,
  publishedDateTo: null,
  author: '',
  journal: '',
  pageNum: 1,
  pageSize: 10
};

interface PaperFilterState {
  topicPaperFilterData: IPaperFilterData;
  communityPaperFilterData: IPaperFilterData;
  topicPapersData: IPaperPaged;
  communityPapersData: IPaperPaged;
  topicLoading: boolean;
  communityLoading: boolean;
  topicError: string | null;
  communityError: string | null;
}

const initialState: PaperFilterState = {
  topicPaperFilterData: initialPaperFilterData,
  communityPaperFilterData: initialPaperFilterData,
  topicPapersData: {} as IPaperPaged,
  communityPapersData: {} as IPaperPaged,
  topicLoading: false,
  communityLoading: false,
  topicError: null,
  communityError: null
};

const papersFilterSlice = createSlice({
  name: 'papersFilter',
  initialState,
  reducers: {
    setTopicPaperFilterData: (
      state,
      action: PayloadAction<IPaperFilterData>
    ) => {
      state.topicPaperFilterData = action.payload;
    },
    resetTopicPaperFilterData: (state) => {
      state.topicPaperFilterData = initialPaperFilterData;
    },
    setCommunityPaperFilterData: (
      state,
      action: PayloadAction<IPaperFilterData>
    ) => {
      state.communityPaperFilterData = action.payload;
    },
    resetCommunityPaperFilterData: (state) => {
      state.communityPaperFilterData = initialPaperFilterData;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle topic papers filtering
      .addCase(fetchTopicPapersData.pending, (state) => {
        state.topicLoading = true;
        state.topicError = null;
      })
      .addCase(
        fetchTopicPapersData.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.topicPapersData = action.payload;
          state.topicLoading = false;
        }
      )
      .addCase(fetchTopicPapersData.rejected, (state, action) => {
        state.topicError = action.payload as string;
        state.topicLoading = false;
      })

      // Handle community papers filtering
      .addCase(fetchCommunityPapersData.pending, (state) => {
        state.communityLoading = true;
        state.communityError = null;
      })
      .addCase(
        fetchCommunityPapersData.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.communityPapersData = action.payload;
          state.communityLoading = false;
        }
      )
      .addCase(fetchCommunityPapersData.rejected, (state, action) => {
        state.communityError = action.payload as string;
        state.communityLoading = false;
      });
  }
});

export const {
  setTopicPaperFilterData,
  resetTopicPaperFilterData,
  setCommunityPaperFilterData,
  resetCommunityPaperFilterData
} = papersFilterSlice.actions;

export const papersFilterReducer = papersFilterSlice.reducer;
