import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IComment } from '../../types/comment.type';
import {
  deleteComment,
  editComment,
  getComments,
  getCommentsWithDeleted,
  postComment
} from '../actions/commentsActions';

interface CommentsState {
  comments: IComment[] | null;
  loading: boolean;
  error: string | null;
  newCommentFormShow: boolean;
}

const initialState: CommentsState = {
  comments: null,
  loading: false,
  error: null,
  newCommentFormShow: false
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    clearComments: (state) => {
      state.comments = null;
      state.loading = false;
      state.error = null;
    },
    // Close all reply and edit forms for every comment
    closeAllComments: (state) => {
      const closeComments = (comments: IComment[]) => {
        comments.forEach((comment) => {
          comment.isReplyOpen = false;
          comment.isEditOpen = false;
          if (comment.comments) {
            closeComments(comment.comments);
          }
        });
      };
      if (state.comments) {
        closeComments(state.comments);
      }
    },
    // Open the reply form for a specific comment by ID
    openReplyForComment: (state, action: PayloadAction<number>) => {
      const targetCommentId = action.payload;

      const openReply = (comments: IComment[]) => {
        for (const comment of comments) {
          if (comment.id === targetCommentId) {
            comment.isReplyOpen = true;
            comment.isEditOpen = false;
            return;
          }
          if (comment.comments) {
            openReply(comment.comments);
          }
        }
      };

      if (state.comments) {
        openReply(state.comments);
      }
    },
    // Open the edit form for a specific comment by ID
    openEditForComment: (state, action: PayloadAction<number>) => {
      const targetCommentId = action.payload;

      const openEdit = (comments: IComment[]) => {
        for (const comment of comments) {
          if (comment.id === targetCommentId) {
            comment.isEditOpen = true;
            comment.isReplyOpen = false;
            return;
          }
          if (comment.comments) {
            openEdit(comment.comments);
          }
        }
      };

      if (state.comments) {
        openEdit(state.comments);
      }
    },
    // Update the reply text for a specific comment by ID
    storeReplyText: (
      state,
      action: PayloadAction<{ commentId: number; text: string }>
    ) => {
      const { commentId, text } = action.payload;

      const updateReplyText = (comments: IComment[]) => {
        comments.forEach((comment) => {
          if (comment.id === commentId) {
            comment.replyText = text;
          }
          if (comment.comments) {
            updateReplyText(comment.comments);
          }
        });
      };

      if (state.comments) {
        updateReplyText(state.comments);
      }
    },
    // Set the visibility of the new comment form
    setNewCommentFormVisibility: (state, action: PayloadAction<boolean>) => {
      state.newCommentFormShow = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getComments.fulfilled,
        (state, action: PayloadAction<IComment[]>) => {
          state.comments = action.payload;
          state.loading = false;
        }
      )
      .addCase(getComments.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch comments';
        state.loading = false;
      })
      .addCase(getCommentsWithDeleted.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCommentsWithDeleted.fulfilled,
        (state, action: PayloadAction<IComment[]>) => {
          state.comments = action.payload;
          state.loading = false;
        }
      )
      .addCase(getCommentsWithDeleted.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch comments';
        state.loading = false;
      })
      .addCase(postComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(postComment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postComment.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to post comment';
        state.loading = false;
      })
      .addCase(editComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(editComment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editComment.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to edit comment';
        state.loading = false;
      })
      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteComment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete comment';
        state.loading = false;
      });
  }
});

export const {
  clearComments,
  closeAllComments,
  setNewCommentFormVisibility,
  openReplyForComment,
  openEditForComment,
  storeReplyText
} = commentsSlice.actions;
export default commentsSlice.reducer;
