import { FC } from 'react';
import { ApiDiscussionsPage } from '../../api/oipub-api';
import { Previewv2 } from '../../components/paper';
import { DiscussionSummaryv2 } from './discussion-summaryv2';

interface Props {
  discussion: ApiDiscussionsPage;
  showThreeItems?: boolean;
}

const DiscussionPaperSummary: FC<Props> = ({
  discussion,
  showThreeItems
}: Props) => {
  if (discussion.paper) {
    return (
      <Previewv2
        key={`Previewv2-${discussion.paper.id}`}
        paper={discussion.paper}
        isDiscussion={true}
        showThreeItems={showThreeItems}
      />
    );
  } else if (discussion.userPost) {
    return (
      <DiscussionSummaryv2
        key={`DiscussionSummaryv2-${discussion.userPost.id}`}
        discussion={discussion.userPost}
        isDiscussion={true}
        showThreeItems={showThreeItems}
      />
    );
  }
  return null;
};

export default DiscussionPaperSummary;
