import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  search,
  searchPapers,
  searchTopicsCommunities
} from '../actions/searchActions';
import { IPaperPaged } from '../../types/papers.type';
import { ICommonHubPaged } from '../../types/hubs.type';
import { ISearchType } from '../../types/search.type';

interface SearchState {
  term: string;
  pageNum: number;
  searchType: ISearchType;
  papers: IPaperPaged;
  hubs: ICommonHubPaged;
  loading: boolean;
  error: string | null;
  requestAbortedForNew: boolean;
}

export const initialState: SearchState = {
  term: '',
  pageNum: 1,
  searchType: ISearchType.Papers,
  loading: false,
  papers: {} as IPaperPaged,
  hubs: {} as ICommonHubPaged,
  error: null,
  requestAbortedForNew: false
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<{
        term: string;
        pageNum: number;
        searchType: ISearchType;
      }>
    ) => {
      state.term = action.payload.term;
      state.pageNum = action.payload.pageNum;
      state.searchType = action.payload.searchType;
    },
    clearSearch: (state) => {
      state.term = '';
      state.pageNum = 1;
      state.searchType = ISearchType.Papers;
      state.papers = {} as IPaperPaged;
      state.hubs = {} as ICommonHubPaged;
      state.loading = false;
      state.error = null;
      state.requestAbortedForNew = false;
    },
    abortRequestForNew: (state) => {
      state.requestAbortedForNew = true;
    }
  },
  extraReducers: (builder) => {
    // Handle search lifecycle actions
    builder
      .addCase(search.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.papers = {};
        state.hubs = {};
        state.requestAbortedForNew = false;
      })
      .addCase(search.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.requestAbortedForNew = false;
      })
      .addCase(search.rejected, (state, action) => {
        state.loading = false;
        if (action.error.name !== 'AbortError' && !state.requestAbortedForNew) {
          state.error =
            action.error.message || 'Failed to fetch topics/communities';
        }
      });

    // Handle searchPapers lifecycle actions
    builder
      .addCase(searchPapers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        searchPapers.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papers = action.payload;
          state.loading = false;
        }
      )
      .addCase(searchPapers.rejected, (state, action) => {
        state.loading = false;
        if (action.error.name !== 'AbortError' && !state.requestAbortedForNew) {
          state.error =
            action.error.message || 'Failed to fetch topics/communities';
        }
      });

    // Handle searchTopicsCommunitiesThunk lifecycle actions
    builder
      .addCase(searchTopicsCommunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        searchTopicsCommunities.fulfilled,
        (state, action: PayloadAction<ICommonHubPaged>) => {
          state.hubs = action.payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(searchTopicsCommunities.rejected, (state, action) => {
        state.loading = false;
        if (action.error.name !== 'AbortError' && !state.requestAbortedForNew) {
          state.error =
            action.error.message || 'Failed to fetch topics/communities';
        }
      });
  }
});

export const { setQuery, clearSearch, abortRequestForNew } =
  searchSlice.actions;

export default searchSlice.reducer;
